<template>
  <b-performance-table
    :data="rows"
    :custom-headers="headers"
    :loading="loading"
    :show-diff="dates.compareWith"
    :data-grouped-by="groupBy"
  />
</template>

<script>
import { get } from 'vuex-pathify';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import performanceDataMixin from '~/components/mixins/performance-data-mixin';

export default {
  name: 'DashDetailPublishersPerformanceList',
  components: {
    BPerformanceTable: () =>
      import(
        /* webpackChunkName: "b-performance-table" */ '~/components/elements/b-performance-table.vue'
      ),
  },

  mixins: [performanceDataMixin],

  data() {
    return {
      loading: false,
      performance: null,
      groupBy: 'publisher',
    };
  },
  computed: {
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    headers() {
      return [{ name: 'Name', sortKey: 'name' }, this.getCommonHeader()];
    },
    rows() {
      if (!this.performance) {
        return [];
      }

      const rows = this.performance.map((item) => {
        const rowItem = this.transformToRowItem(item);
        rowItem.name = item.displayName || item.name;
        rowItem.imgSrc =
          item.imageUrl ||
          'https://storage.googleapis.com/flip-assets/publisher_logos/color/otherhub_80x80.png';
        rowItem.nameColumnStyle = 'both';
        rowItem.subText = item.streamingCategory;
        return rowItem;
      });

      return this.sortRows(rows);
    },
  },
  async mounted() {
    try {
      await this.loadPerformanceData(this.payload);
    } catch (err) {
      console.error('error mounting DashDetailPublishersPerformanceList ->', err);
    }
  },
  methods: {
    async loadPerformanceData(payload) {
      this.loading = true;
      payload = { ...payload };
      payload.groupBy = this.groupBy;
      payload = { ...payload };
      try {
        const dataValidated = this.validateParams(payload, this.advertiser);
        let result = [];
        let incReachMetricsData = {};
        if (dataValidated) {
          result = await advertiserReportsAPI.performance(
            this.advertiser.id,
            buildQueryString(payload)
          );
          if (this.selectedEvent?.isIncrementalReach) {
            incReachMetricsData = await advertiserReportsAPI.incremental_reach_metrics(
              this.advertiser.id,
              buildQueryString(payload)
            );
            this.$store.dispatch('dashboard/setIncReachMetric', incReachMetricsData);
          }

          result = result.map((item) => {
            return {
              ...item,
              incrementalReachPercentage: {
                change: item?.ireachPercentage?.change,
                compareValue: item?.ireachPercentage?.compareValue,
                currentValue: item?.ireachPercentage?.currentValue || 0,
                isChangeInfinity: item?.ireachPercentage?.isChangeInfinity || false,
                isCurrentInfinity: item?.ireachPercentage?.isCurrentInfinity || false,
              },
            };
          });
        }
        this.performance = result;
      } catch (err) {
        console.error('error fetching Publishers Performance ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .no-bg.zero {
  margin-top: 0 !important;
  margin-left: -10px !important;
}
</style>
